import React from 'react';
import QRContactCard from '../Components/QRContactCard/QRContactCard';

function ContactCardsPage() {
    return (
        <div className='container-contact-card'
            style={{
                display: 'flex',
                justifyContent: 'center', // Centers the QRContactCard horizontally
                alignItems: 'center', // Centers the QRContactCard vertically
                padding: '20px', // Adds padding around the container

            }}
        >
            <QRContactCard />
        </div>
    );
}

export default ContactCardsPage;