import React, { useEffect } from 'react';
import './QRContactCard.css'; // Import external CSS file
import contactImage from '../../assets/business-cards/darryl.png';
import { Button } from 'react-bootstrap';

const QRContactCard = () => {

    useEffect(() => {
        // Apply styles for QRContactCard
        document.body.classList.add('contact-card-background');

        // Hide other elements on the page
        const navbar = document.querySelector('.secondary-navbar');
        const header = document.querySelector('.app-header');
        const footer = document.querySelector('.hf-footer-main-div');
        const primaryNav = document.querySelector('header.App-header');


        if (navbar) navbar.style.display = 'none';
        if (header) header.style.display = 'none';
        if (footer) footer.style.display = 'none';
        if (primaryNav) primaryNav.display = 'none';

        // Cleanup styles on component unmount
        return () => {
            document.body.classList.remove('contact-card-background');
            if (navbar) navbar.style.visibility = 'visible';
            if (header) header.style.visibility = 'visible';
            if (footer) footer.style.visibility = 'visible';
        };
    }, []);

    // Business card details
    const contactDetails = {
        firstName: "Darryl",
        lastName: "Ballantyne",
        company: "HANSA-FLEX AUSTRALIA",
        title: "Managing Director",
        phone: "+61438057452",
        email: "darryl.ballantyne@hansa-flex.com",
        address: "HANSA-FLEX Australia, Jijaws St 11/45, 4074 Sumner Park, Australia",
    };

    // Function to open the phone dialer with prefilled number
    const handleSaveContact = () => {
        window.location.href = `tel:${contactDetails.phone}`;
    };

    // Function to generate and download vCard file
    const downloadVCard = () => {
        const { firstName, lastName, company, title, phone, email, address } = contactDetails;

        // vCard content
        const vCardData = `
BEGIN:VCARD
VERSION:3.0
FN:${firstName} ${lastName}
ORG:${company}
TITLE:${title}
TEL:${phone}
EMAIL:${email}
ADR;TYPE=WORK,PREF:;;${address}
END:VCARD
    `.trim();

        // Create a Blob for the vCard file
        const blob = new Blob([vCardData], { type: "text/vcard" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${firstName}_${lastName}.vcf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <div className="contact-card-container">
            {/* <div className="card-container">
                <div className="card">
                    <h2>{`${contactDetails.firstName} ${contactDetails.lastName}`}</h2>
                    <p className="title">{contactDetails.title}</p>
                    <p>{contactDetails.company}</p>
                    <p>Phone: <a href={`tel:${contactDetails.phone}`}>{contactDetails.phone}</a></p>
                    <p>Email: <a href={`mailto:${contactDetails.email}`}>{contactDetails.email}</a></p>
                    <p>Address: {contactDetails.address}</p>
                </div>
            </div> */}

            <div className="image-container-qr-contact">
                <img src={contactImage} alt="Contact" className="contact-image-qr-contact" />
            </div>

            <div >
                <div className="button-container-qr-contact">
                    {/* <Button
                        variant="info" // You can choose other variants like "secondary", "success", etc.
                        onClick={handleSaveContact}
                        className='btn-qrct'>
                        Save Contact
                    </Button>
                    <div className='seperator-div'> 

                </div>*/}
                    <Button
                        variant="info" // Choose a variant that fits your design
                        onClick={downloadVCard}
                        className='btn-qrct'
                    >
                        Add to contacts !
                    </Button>
                </div>
            </div>
        </div >
    );
};

export default QRContactCard;
